import store from "services/store";
import api from "services/api";

import Validator from "services/validator";
import {
  Missing,
  isKubernetesName,
  MaxLength,
  ApplyIf,
} from "services/validator/rules";
import createActions from "modules/form/actions";

import {
  awsPolicesFetcher,
  cloudAccountFetcher,
  cloudAccountFormModal,
  CLOUD_ACCOUNTS_MODULE,
  selfHostedOverlordsFetcher,
} from "state/cloudaccounts/services";
import { createAccount } from "state/cloudaccounts/actions/create";
import { getAwsCloudAccountPayload } from "state/cloudaccounts/selectors";

export const AWS_PARTITION_VALUES = {
  AWS: "aws",
  C2S: "aws-iso", // top secret region AWS ISO (US)
  SC2S: "aws-iso-b", // secret region AWS ISOB (US)
};

export function isSecretPartition(partition) {
  return isC2SPartition(partition) || isSC2SPartition(partition);
}

const validator = new Validator();
validator.addRule(["name"], Missing());
validator.addRule(["name"], isKubernetesName());
validator.addRule(["name"], MaxLength(32));

validator.addRule(["accessKey", "secretKey"], (value, key, data) => {
  if (
    data.credentialType === "secret" &&
    (isSecretPartition(data.partition)
      ? !data.secretSpec.isCapValidationEnabled
      : true)
  ) {
    return Missing()(value, key, data);
  }
  return false;
});

validator.addRule(["arn", "externalId", "accountId"], (value, key, data) => {
  if (data.credentialType === "sts") {
    return Missing()(value, key, data);
  }
  return false;
});
validator.addRule(
  ["overlordUid"],
  ApplyIf((value, key, data) => !!data?.useSelfHostedPCG, Missing())
);

function getPartition() {
  return store.getState()?.forms?.cloudAccounts?.data?.partition || "";
}

function isCapValidationEnabled() {
  return store.getState()?.forms?.cloudAccounts?.data?.secretSpec
    ?.isCapValidationEnabled;
}

function isSC2SPartition(partition) {
  return partition === AWS_PARTITION_VALUES.SC2S;
}

function isC2SPartition(partition) {
  return partition === AWS_PARTITION_VALUES.C2S;
}

function secretPartitionAndCapEnabled() {
  return isSecretPartition(getPartition()) && isCapValidationEnabled();
}

function c2SPartitionAndCapEnabled(value, key, data) {
  return isC2SPartition(getPartition()) && data.isCapValidationEnabled;
}

function sc2SPartitionAndCapEnabled(value, key, data) {
  return isSC2SPartition(getPartition()) && data.isCapValidationEnabled;
}

const credentialsValidator = new Validator();
const tlsValidator = new Validator();

credentialsValidator.addRule(
  ["agency", "role"],
  ApplyIf(secretPartitionAndCapEnabled, Missing())
);

credentialsValidator.addRule(
  ["mission"],
  ApplyIf(c2SPartitionAndCapEnabled, Missing())
);

credentialsValidator.addRule(
  ["accountName"],
  ApplyIf(sc2SPartitionAndCapEnabled, Missing())
);

tlsValidator.addRule(
  ["cert", "key"],
  ApplyIf(secretPartitionAndCapEnabled, Missing())
);

tlsValidator.addRule(
  ["ca"],
  ApplyIf(() => isSecretPartition(getPartition()), Missing())
);

credentialsValidator.addRule("tls", tlsValidator);

validator.addRule("secretSpec", credentialsValidator);

async function submit(data) {
  const {
    credentialType,
    arn,
    externalId,
    accountId,
    policyARNs,
    enablePolicies,
    partition,
    secretSpec,
  } = data;
  const commonData = {
    credentialType,
    policyARNs: enablePolicies ? policyARNs : [],
    partition,
  };

  const getCredentials = () => {
    if (credentialType === "sts") {
      return {
        ...commonData,
        sts: {
          arn,
          externalId,
          accountId,
        },
      };
    }

    if (isSecretPartition(partition)) {
      return {
        ...commonData,
        ...(!data.secretSpec.isCapValidationEnabled && {
          accessKey: data.accessKey?.trim(),
          secretKey: data.secretKey?.trim(),
        }),
        secretSpec: {
          accountName: secretSpec.accountName,
          agency: secretSpec.agency,
          mission: secretSpec.mission,
          role: secretSpec.role,
          namePrefix: secretSpec.namePrefix,
          permissionBoundary: secretSpec.permissionBoundary,
          isCapValidationEnabled: secretSpec.isCapValidationEnabled,
          tls: {
            ca: secretSpec.tls.ca,
            cert: secretSpec.tls.cert,
            key: secretSpec.tls.key,
          },
        },
      };
    }

    return {
      ...commonData,
      accessKey: data.accessKey.trim(),
      secretKey: data.secretKey.trim(),
    };
  };

  const payload = {
    metadata: {
      name: data.name,
      annotations: {
        description: data.description,
        overlordUid: data.useSelfHostedPCG ? data.overlordUid : "",
      },
    },
    spec: getCredentials(),
  };

  return store.dispatch(createAccount("aws", payload));
}

async function init() {
  let data;
  let policyARNs = [];
  const stsData = await api.get("v1/clouds/aws/account/sts");

  store.dispatch(selfHostedOverlordsFetcher.fetch());

  if (cloudAccountFormModal.data?.uid) {
    data = await store.dispatch(cloudAccountFetcher.fetch());
    store.dispatch({
      type: "SET_ACCOUNT_AS_VALID",
    });

    const payload = {
      secret: {
        accessKey: data?.spec?.accessKey,
        secretKey: data?.spec?.secretKey,
        partition: data?.spec?.partition,
      },
      sts: {
        credentialType: data?.spec?.credentialType,
        sts: {
          arn: data?.spec?.sts?.arn,
          externalId: stsData?.accountId,
          accountId: stsData?.externalId,
        },
        partition: data?.spec?.partition,
      },
    };

    policyARNs = [...(data?.spec?.policyARNs || [])];

    if (policyARNs.length > 0) {
      store.dispatch(
        awsPolicesFetcher.fetch({
          cloudAccountUid: cloudAccountFormModal.data.uid,
          payload,
        })
      );
    }
  }

  return Promise.resolve({
    name: data?.metadata?.name || "",
    accessKey: data?.spec?.accessKey || "",
    secretKey: data?.spec?.secretKey || "",
    description: data?.metadata?.annotations?.description || "",
    credentialType: data?.spec?.credentialType || "secret",
    arn: data?.spec?.sts?.arn || "",
    accountId: stsData?.accountId,
    externalId: stsData?.externalId,
    policyARNs,
    enablePolicies: !!policyARNs.length > 0,
    partition: data?.spec?.partition || "aws",
    overlordUid: data?.metadata?.annotations?.overlordUid,
    useSelfHostedPCG: !!data?.metadata?.annotations?.overlordUid,
    secretSpec: {
      agency: data?.spec?.secretSpec?.agency || "",
      mission: data?.spec?.secretSpec?.mission || "",
      isCapValidationEnabled:
        data?.spec?.secretSpec?.isCapValidationEnabled || false,
      accountName: data?.spec?.secretSpec?.accountName || "",
      role: data?.spec?.secretSpec?.role || "",
      namePrefix: data?.spec?.secretSpec?.namePrefix || "",
      permissionBoundary: data?.spec?.secretSpec?.permissionBoundary || "",
      tls: {
        ca: data?.spec?.secretSpec?.tls?.ca || "",
        cert: data?.spec?.secretSpec?.tls?.cert || "",
        key: data?.spec?.secretSpec?.tls?.key || "",
      },
    },
  });
}

export function onIAMPoliciesToggle(value) {
  return (dispatch, getState) => {
    dispatch(
      awsAccountFormActions.onChange({
        name: "enablePolicies",
        value,
        module: CLOUD_ACCOUNTS_MODULE,
      })
    );

    if (value) {
      const payload = getAwsCloudAccountPayload(getState());
      dispatch(
        awsPolicesFetcher.fetch({
          payload,
          cloudAccountUid: cloudAccountFormModal?.data?.uid,
        })
      );
    }
  };
}

export function onPartitionFieldChange(value) {
  return async (dispatch) => {
    dispatch(
      awsAccountFormActions.onChange({
        name: "partition",
        value,
        module: CLOUD_ACCOUNTS_MODULE,
      })
    );

    if (isSecretPartition(value)) {
      return;
    }

    const stsData = await api.get("v1/clouds/aws/account/sts", {
      partition: value,
    });

    dispatch(
      awsAccountFormActions.batchChange({
        module: CLOUD_ACCOUNTS_MODULE,
        updates: {
          accountId: stsData?.accountId,
          externalId: stsData?.externalId,
        },
      })
    );
  };
}

export const awsAccountFormActions = createActions({
  submit,
  validator,
  init,
});
